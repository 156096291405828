/*        COLOR        */

$blue: #008cc3;
$orange: #f25724;
$orange-Secondary: #efe9e8;
$blue-Secondary: #dce2e5;
$orange-Secondary-Black: #42140b;
$blue-Secondary-Black: #002e43;

/****************************/

$SideBar-width: 275px;
$Border-Radius: 5px;
