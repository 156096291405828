/* Ajusta la altura de los ítems del menú y añade padding */
.UISideBarBTNNav {
  display: flex;
  align-items: center; /* Alinea verticalmente el contenido */
  height: 50px; /* Ajusta la altura de los ítems del menú */
  margin: 5px 0; /* Añade margen entre los ítems */
  padding: 0 15px; /* Añade padding para mejor espaciado */
  border-radius: 8px; /* Bordes redondeados para un diseño moderno */
  user-select: none; /* Evita la selección de texto */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Transiciones suaves */
}

.UISideBarBTNNav:hover {
  background-color: var(--orange-Secondary); /* Color de fondo en hover */
  transform: translateX(5px); /* Efecto de desplazamiento en hover */
}

/* Estilo para el ítem activo */
.active {
  background-color: var(--orange-Secondary); /* Color de fondo para el ítem activo */
}

/* Imagen dentro del ítem del menú */
.UISideBarBTNNav img {
  width: 20px; /* Tamaño ajustado de la imagen */
  margin-right: 15px; /* Espacio entre la imagen y el texto */
}

/* Estilo para la barra de color en el ítem del menú */
.UISideBarBTNNav__Color {
  position: absolute;
  width: 5px; /* Ancho reducido para la barra de color */
  height: 80%; /* Ajusta la altura de la barra de color */
  right: 0; /* Posiciona la barra a la derecha */
  top: 50%; /* Centra verticalmente la barra */
  transform: translateY(-50%); /* Corrige el centrado vertical */
  background: linear-gradient(45deg, var(--blue), var(--orange)); /* Degradado de color */
  transition: transform 0.3s ease; /* Transición suave */
}

.UISideBarBTNNav__Color img {
  width: 25px; /* Tamaño ajustado de la imagen dentro de la barra de color */
  opacity: 0; /* Oculta la imagen inicialmente */
  transition: opacity 0.3s ease; /* Transición suave para la opacidad */
}

/* Estilo para el enlace dentro del ítem del menú */
.UISideBarBTNNav a {
  margin-left: 10px;
  color: #654; /* Color del texto */
  font-size: 16px; /* Tamaño ajustado de la fuente */
  text-decoration: none; /* Elimina el subrayado del enlace */
  font-weight: normal; /* Estilo de fuente normal */
  transition: color 0.3s ease, font-weight 0.3s ease; /* Transiciones suaves */
}

.UISideBarBTNNav a:hover {
  color: var(--orange); /* Cambia el color del texto en hover */
}

/* Estilo cuando el ítem está activo */
.active .UISideBarBTNNav__Color {
  transform: translateX(0); /* Muestra la barra de color */
}

.active .UISideBarBTNNav__Color img {
  opacity: 1; /* Muestra la imagen dentro de la barra de color */
  transition-delay: 0.3s; /* Retraso en la transición para la imagen */
}

.active a {
  font-weight: bold; /* Resalta el texto del ítem activo */
  color: var(--orange); /* Cambia el color del texto del ítem activo */
  pointer-events: none; /* Desactiva la interactividad para el ítem activo */
}