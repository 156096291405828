.UIDataUser {
  height: 110px;
  // border-bottom: 80px solid $blue-Secondary;
  // border-bottom: 60px solid $orange-Secondary;
  margin-bottom: 50px;
  padding-top: 20px;
  transition: all 0.3s;
  text-align: center;
  background-color: $blue;
}

.UIDataUser h3 {
  letter-spacing: 0.15em;
  color: $orange-Secondary;
  margin-bottom: 5px;
}

.UIDataUser h5 {
  letter-spacing: 0.12em;
  color: $orange-Secondary;
  opacity: 0.5;
}

.UIDataUser img {
  object-fit: cover;
  width: 70px;
  height: 70px;
  border: 8px solid $blue-Secondary;
  margin-top: 20px;
  border-radius: 50%;
}
