.app {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.app-main {
  height: calc(100vh - 56px);
  grid-area: 2 / 2 / 2 / 2;
  z-index: 0;
  overflow: auto;
  overflow-x: hidden;
  margin: 5px;
  padding: 5px;
}

.app-sideBar {
  grid-area: 1 / 1 / 3 / 2;
  box-shadow: 0px 0px 5px 0px #00000050;
  z-index: 1000;
}

/* TEXT INPUT */
.TextInput {
  display: flex;
  padding: 4px 0;
  width: 100%;
  height: 50px;
  overflow: hidden;
  align-items: flex-end;

  // background-color: #098;
  margin-top: 10px;
}

.TextInput img {
  position: absolute;
  margin: 8px;
  width: 16px;
  height: 16px;
}

.TextInput--Input {
  width: 100%;
  padding: 6px;
  padding-left: 40px;
  background: none;
  border: 1px solid $blue;
  border-radius: $Border-Radius;
  color: $blue-Secondary-Black;
  font-size: 1em;
  font-weight: bold;
  outline: 0px;
  z-index: 1;
  transition: border-color 0.3s ease;
}

.TextInput--Label {
  position: absolute;
  align-self: flex-end;
  text-align: left;
  margin-left: 40px;
  color: $orange;
  transform: translateY(-6px);
  transition: all 0.4s cubic-bezier(0, 0, 0, 1);
}

.TextInput--Input:focus {
  border-color: $orange;
}

// .TextInput--Input:valid + .TextInput--Label {
//   color: $blue;
//   border-radius: 5px 5px 0px 0px;
//   transform: translateY(-35px);
//   font-size: 14px;
//   margin-left: 20px;
// }

/*
.TextInput--Input:not(:valid) {
  background-color: #798;
}
.TextInput--Input:valid {
  background-color: #798;
}
*/
.TextInput--Input:not(:placeholder-shown) + .TextInput--Label {
  color: $blue;
  border-radius: 5px 5px 0px 0px;
  transform: translateY(-35px);
  font-size: 14px;
  margin-left: 20px;
}