/*  FONT  */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

* {
  margin: 0;
  padding: 0;
  margin: 0;
  position: relative;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  text-decoration: none;
  user-select: none;
}

/*  VARIABLES   */
@import "./_Variables";

/*  IMPORT SCSS COMPONENT  */
@import "./_App";

/*  NavBar */
@import "./component/header/NavBar";
@import "./component/sidebar/sideBar";

/*  PAGES   */
@import "./pages/login/login.scss";


/* ui */
@import "./ui/DataUser/UIDataUser";
@import "./ui/SideBarBTNNav/_UISideBarBTNNav";
@import "./ui/BTNCircle/_UIBTNCircle";
@import "./ui/TextInput/_UITextInput.scss";
@import "./ui/BTNPrimary/_UIBTNPrimary";
@import "./ui/BTNSecondary/_UBTNSecondary";


html,
body {
  background-color: $orange-Secondary;
  overflow: hidden;
}
