.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 4 columnas de igual tamaño */
    grid-template-rows: repeat(2, 1fr); /* 2 filas de igual tamaño */
    gap: 10px; /* Espacio entre los elementos */
    height: 100%; /* Ocupa el 100% de la altura del componente padre */
    overflow: auto; /* Habilita el scroll automático cuando el contenido excede la altura */
}

/* Estilo para los elementos dentro de la cuadrícula */
.grid-item {
    background-color: #f4f4f4; /* Color de fondo opcional para ver los elementos */
    padding: 10px; /* Espacio interno opcional */
    border: 1px solid #ddd; /* Borde opcional */
}

/* Estilo específico para ChartFactures para que ocupe dos filas en la columna derecha */
.chart-factures {
    grid-column: 2 / 3; /* Ocupará la columna 3 y la 4 (de columna 3 a columna 5, sin incluir la 5) */
    grid-row: 1 / 3; /* Ocupará desde la fila 1 hasta la fila 3 (es decir, dos filas) */
}