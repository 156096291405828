.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #333;
  color: white;
  height: 60px;
  border-bottom: 2px solid #444;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-nav a.active {
  border-bottom: 2px solid #f39c12;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-button {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.header-button:hover {
  opacity: 0.8;
}

.icon {
  display: inline-block;
  font-size: 20px;
}