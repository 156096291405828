.UIBTNCircle {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  transition: all 0.3s;
}

.UIBTNCircle:hover {
  background-color: #00000010;
}

.UIBTNCircle__img {
  margin: 10px;
  user-select: none;
}
