/* General styles */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.loginRegister {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #002e43;
  overflow: hidden;
}

/* Container styles */
.loginRegister__body {
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  width: 100%;
  max-width: 500px;
  height: 80%;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 1px 4px 20px rgba(0, 0, 0, 0.2);
//  overflow: auto;
}

/* Header styles */
.loginRegister__header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.loginRegister__header img {
  height: 100px;
}

/* Article styles */
.loginRegister__article {
  display: flex;
  flex-direction: row;
  transition: transform 0.4s ease;
  align-items: center;
  justify-content: center;
}

/* Login and Register sections */
.loginRegister__login,
.loginRegister__register {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

/* Footer styles */
.loginRegister__footer {
  text-align: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
}

.textInput {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* Aligns items to the right */
  gap: 15px;
  /* Adds space between inputs */
  margin-bottom: 15px;
  /* Adds space below the input section */
}

/* Label styles */
.textInput label {
  font-weight: bold;
  /* Makes the text bold */
  color: #003366;
  /* Strong blue color for the text */
  margin-bottom: 5px;
  /* Adds space between the label and input */
  text-align: right;
  /* Aligns the label text to the right */
  width: 100%;
  /* Ensures label takes up full width of container */
}

/* Input field styles */
.textInput input[type="text"],
.textInput input[type="password"] {
  width: 100%;
  /* Makes the input fill the container */
  padding: 10px;
  border: 2px solid #003366;
  /* Blue border for visibility */
  border-radius: 5px;
  box-sizing: border-box;
  /* Includes padding and border in element's total width and height */
  font-size: 20px;
}

/* Additional input focus styles */
.textInput input[type="text"]:focus,
.textInput input[type="password"]:focus {
  outline: none;
  /* Removes default outline */
  border-color: #ff6f00;
  /* Changes border color on focus */
  box-shadow: 0 0 5px rgba(255, 111, 0, 0.5);
  /* Adds subtle shadow */
}

/* Input checkbox styling */
input[type="checkbox"] {
  display: none;
}

input:checked~.loginRegister__article {
  transform: translateX(-100%);
}

/* Form elements */
input[type="text"],
input[type="password"],
button {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

button {
  background-color: #ff6f00;
  /* Orange button background */
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #e65c00;
  /* Darker orange on hover */
}