/* Archivo CSS consolidado */

.SideBar {
  height: 100vh; /* Asegura que el sidebar ocupe toda la altura de la ventana */
  width: var(--SideBar-width);
  background-color: var(--blue-Secondary);
  transition: all 0.4s cubic-bezier(0, 0, 0, 1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra */
  border-radius: 8px; /* Bordes redondeados */
  overflow-y: auto; /* Añade scroll vertical si es necesario */
}

/* Asegura que el contenido del sidebar esté correctamente ajustado */
.SideBar__Header {
  display: flex;
  height: 56px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--blue);
  border-bottom: 2px solid var(--blue-dark); /* Borde inferior */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra sutil */
}
/* Estilo del Logo en el Sidebar */
.SideBar__Logo {
  margin-left: 5px;
  height: 40px;
  cursor: pointer;
  transition: transform 0.3s ease; /* Transición para el hover */
}

.SideBar__Logo:hover {
  transform: scale(1.1); /* Aumenta el tamaño en hover */
}

/* Estilo del botón de cierre en el Header del Sidebar */
.SideBar--BodyBTNClose {
  margin-right: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transición suave para el hover */
}

.SideBar--BodyBTNClose:hover {
  background-color: var(--blue-dark); /* Cambia el color en hover */
}

/* Estilo del checkbox que controla la visibilidad del Sidebar */
.SideBar--InputBTNClose {
  display: none;
}

/* Estilo para mostrar el Sidebar cuando el checkbox está marcado */
.SideBar--InputBTNClose:checked + .SideBar {
  margin-left: 0; /* Muestra el sidebar */
  background-color: var(--blue); /* Cambia el color cuando está activo */
}

/* Estilo para el botón de cierre cuando el checkbox está marcado */
.SideBar--InputBTNClose:checked + div label .SideBar--BodyBTNClose {
  margin-left: calc(var(--SideBar-width) - 145px); /* Ajusta la posición */
  transition: margin-left 1.2s cubic-bezier(0, 0, 0, 1); /* Transición suave para el margin-left */
}
