.btnSecondary {
  border: none;
  font: inherit;
  cursor: pointer;
}

.btnSecondary {
  margin-top: 15px;
  padding: 5px;
  border-radius: 5px;
  font-size: 1em;
  // font-weight: bold;
  // letter-spacing: 0.15em;
  color: $blue;
  transition: all 0.3s ease;
}

.btnSecondary:hover {
  // background-color: $blue;
  color: $orange;
  // box-shadow: 0px 0px 5px 0px #00000050;
}
