.btnPrimary {
  border: none;
  font: inherit;
  cursor: pointer;
}

.btnPrimary {
  width: 100%;
  height: 35px;
  margin-top: 30px;
  background-color: $orange;
  padding: auto;
  border-radius: $Border-Radius;
  font-weight: bold;
  font-size: 1.2em;
  letter-spacing: 0.15em;
  color: $blue-Secondary;
  transition: all 0.3s ease;
}

.btnPrimary:hover {
  background-color: $blue;
  color: $orange-Secondary;
}
